import react, { useEffect } from "react";
import { NavLink, Outlet, useParams, useLocation, useNavigate } from "react-router-dom";

const Dogs = () => {

    let location = useLocation();
    let nav = useNavigate()
    useEffect(() => {
      if (location.pathname == "/dogs") {
        nav("girls")
      }

    });
    return (
      <div>

        <section >
          <div className="row">
            <div className="col s12">
              <Outlet/>
            </div>
          </div>
        </section>
      </div>
    );
  }

export default Dogs