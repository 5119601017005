import { stringify } from "querystring";
import react from "react";
import "./pedigree.css"
function About(props: any) {
    return (
        
        <div className="pedigree">
            <div id="wrapper" ><span className="label">{props.self}</span>
                <div className="branch">

                    <div className="entry"><span className="label">{props.lineage[0]}</span>
                        <div className="branch">

                            <div className="entry"><span className="label">{props.lineage[2]}</span>
                                <div className="branch">
                                    <div className="entry"><span className="label">{props.lineage[6]}</span></div>
                                    <div className="entry"><span className="label">{props.lineage[7]}</span></div>
                                </div>
                            </div>

                            <div className="entry"><span className="label">{props.lineage[3]}</span>
                                <div className="branch">
                                    <div className="entry"><span className="label">{props.lineage[8]}</span></div>
                                    <div className="entry"><span className="label">{props.lineage[9]}</span></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="entry"><span className="label">{props.lineage[1]}</span>
                        <div className="branch">

                            <div className="entry"><span className="label">{props.lineage[4]}</span>
                                <div className="branch">
                                    <div className="entry"><span className="label">{props.lineage[10]}</span></div>
                                    <div className="entry"><span className="label">{props.lineage[11]}</span></div>
                                </div>
                            </div>

                            <div className="entry"><span className="label">{props.lineage[5]}</span>
                                <div className="branch">
                                    <div className="entry"><span className="label">{props.lineage[12]}</span></div>
                                    <div className="entry"><span className="label">{props.lineage[13]}</span></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default About
