import react, { useEffect} from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import mainImage from "../../assets/img/bowie.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";
import { bowie_details } from "../../data/dogs/bowie_detail";
import { bowie_lineage } from "../../data/dogs/bowie_lineage";

function DogBowie() {
  let location = useLocation()
  useEffect(() => {
    
    if (location.pathname.includes("dogs")) {
      document.title = "Bowie - High Altitude Labs"

      let e = document.getElementById('canonicallink')
      if(e != null) {
        document.head.removeChild(e)
      }

      let link:HTMLLinkElement = document.createElement('link')

      link.id = "canonicallink"
      link.rel = 'canonical';
      link.href = 'https://highaltitudelabs.com/dogs/girls/bowie/';
      link.setAttribute("data-react-helmet","true")
      document.head.appendChild(link);


    }
  },[])
  return (
    <div>
      
      <header></header>
      <section>
        <div className="container">

          <div>
            
            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                <h5>Riverlane's Voglebach All Wrapped Up</h5>
                  <div className="card-image z-depth-2">
                    <img  src={mainImage}  />
                  </div>
                </div>

                <div className="col s12 m6">
                  <div className="container">
              <DogDetails name='"Bowie"' content={(<div></div>)} detailItems={bowie_details}/>

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="Riverlane's Voglebach All Wrapped Up" lineage={bowie_lineage}/>
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogBowie
