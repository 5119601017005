import react, { useEffect } from "react";
import ImageSlide from "../ImageSlide";
import { Link, NavLink } from "react-router-dom";

function PuppiesAvailable() {
  useEffect(() => {

    document.title = "Available Puppies - High Altitude Labs"

    let e = document.getElementById('canonicallink')
    if (e != null) {
      document.head.removeChild(e)
    }

    let link: HTMLLinkElement = document.createElement('link')

    link.id = "canonicallink"
    link.rel = 'canonical';
    link.href = 'https://highaltitudelabs.com/puppies/available/';
    link.setAttribute("data-react-helmet", "true")
    document.head.appendChild(link);


  }, [])

  return (

      <div className="container">
        <div className="row">
          <div className="col s12 m6">Female<img src="/img/miralitter02/gold/p01.jpg" /></div>
        </div>
    </div>

  );
}

export default PuppiesAvailable
