import react, { useEffect} from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import mainImage from "../../assets/img/mira.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";
import { mira_lineage } from "../../data/dogs/mira_lineage";

function DogMira() {
  let location = useLocation()
  useEffect(() => {
    
    if (location.pathname.includes("dogs")) {
      document.title = "Mira - High Altitude Labs"

      let e = document.getElementById('canonicallink')
      if(e != null) {
        document.head.removeChild(e)
      }

      let link:HTMLLinkElement = document.createElement('link')

      link.id = "canonicallink"
      link.rel = 'canonical';
      link.href = 'https://highaltitudelabs.com/dogs/girls/mira/';
      link.setAttribute("data-react-helmet","true")
      document.head.appendChild(link);


    }
  },[])
  return (
    <div>
      
      <header></header>
      <section>
        <div className="container">

          <div>
            
            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                <h5>Riverlane's Miracle</h5>
                  <div className="card-image z-depth-2">
                    <img  src={mainImage}  />
                  </div>
                </div>

                <div className="col s12 m6">
                  <div className="container">
                  <DogDetails name='"Mira"' content={(<div></div>)} detailItems={[{id: 1, text: "Status", value: "co-owned"},{id: 2, text: "DOB", value:"5/25/2018"},{id: 3, text: "AKC", value:"SS06004002"}
                  ,{id: 4, text: "OFA Heart Color Doppler", value:"LR-ACA1563/12F-VPI - Clear"},{id: 5, text: "OFA Hips", value:"LR-LR-247481G24F-VPI - Good"},{id: 6, text: "Elbows", value:"LR-EL96329F24-VPI"}
                  ,{id: 7, text: "Eyes", value:"Cleared Yearly"},{id: 8, text: "Genotype", value:"B/B"},{id: 9, text: "Labrador Panel", value:"All tests clear"}
                ]}/>

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="Riverlane's Miracle" lineage={mira_lineage}/>
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogMira
