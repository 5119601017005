import { mkdirSync } from "fs";
import react, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const DogsGirls = () => {
    let location = useLocation();
    let nav = useNavigate()
    useEffect(() => {
      if (location.pathname.endsWith("girls")) {
        nav("mira")
      }
    });
    console.log(location)
    return (
      <div>

        <section >

            <div className="row">
            <div className="col s12">
              <Outlet/>
            </div>
          </div>
        </section>
      </div>
    );
  }

export default DogsGirls