import react, { Component, useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const DogsBoys = () =>{ 
  let location = useLocation();
  let nav = useNavigate()
  useEffect(() => {
    if (location.pathname.endsWith("boys")) {
      nav("goose")
    }
  });
  console.log(location)
  return (
    <div>

      <section >

          <div className="row">
          <div className="col s12">
            <Outlet/>
          </div>
        </div>
      </section>
    </div>
  );
}
export default DogsBoys