export const maso_lineage = [
    "Ch Wood Duck Double The Odds",
    "Riverlane's Miracle",
    "Ch Devonshires Huckleberry Finn",
    "Ch Belgold From The Crow's Nest",
    "CH Zinfndel Lengley\'s Big Papi",
    "Riverlane Skye's Black Friday", 
    "Gch Tabatha's Sunfest Decker",
    "Ch Devonshires Wild Berry Zinger",
    "Gch Laurglen Ardent Rogue At Hyspire",
    "Grasslands Black Pearl",
    "CH Ghoststone Hyspire Dressed to Impress", 
    "CH Ghoststone's Hollywood Blond", 
    "CH Skyes Big Black Guy JH CD", 
    "CH Westdale Wind in the Willows"
];