import react, { useEffect} from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import mainImage from "../../assets/img/maso.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";
import { maso_details } from "../../data/dogs/maso_detail";
import { maso_lineage } from "../../data/dogs/maso_lineage";

function DogMaso() {
  let location = useLocation()
  useEffect(() => {
    
    if (location.pathname.includes("dogs")) {
      document.title = "Maso - High Altitude Labs"

      let e = document.getElementById('canonicallink')
      if(e != null) {
        document.head.removeChild(e)
      }

      let link:HTMLLinkElement = document.createElement('link')

      link.id = "canonicallink"
      link.rel = 'canonical';
      link.href = 'https://highaltitudelabs.com/dogs/boys/maso/';
      link.setAttribute("data-react-helmet","true")
      document.head.appendChild(link);


    }
  },[])
  return (
    <div>
      
      <header></header>
      <section>
        <div className="container">

          <div>
            
            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                <h5>High Altidude's Mas O Menos</h5>
                  <div className="card-image z-depth-2">
                    <img  src={mainImage}  />
                  </div>
                </div>

                <div className="col s12 m6">
                  <div className="container">
                  <DogDetails name='"Maso"' content={(<div></div>)} detailItems={maso_details}/>

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="High Altidude's Mas O Menos" lineage={maso_lineage}/>
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogMaso
