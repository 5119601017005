export const goose_lineage = [
    "Riverlane's Miracle",
    "CH Gallivant Black Russian",
    "CH Aster Liberti Labro Hamburg",
    "CH Gallivant BigPines Kenya",
    "CH Zinfndel Lengley\'s Big Papi",
    "Riverlane Skye's Black Friday",
    "CH RUS Night Single Great Genus Winners", 
    "RUS CH Aster Liberti Labro Gollandiya", 
    "GCH Can CH Paradocs Bellwether Heath", 
    "BISS GCH CH Big Pine Faith In Africa",
    "CH Dickendall Buckstone Three Dog Night", 
    "Lengley's Consider The Lillies Of Zinfndel", 
    "CH Ghoststone's Otterly Fantastic Hollywood Kid JH", 
    "CH Skye's Are You The One JH CD",
];
