import react, { useEffect } from "react";
import { Link } from "react-router-dom";

const EnrichmentProgram = () => {
  useEffect(() => {

    document.title = "Puppy Enrichment - High Altitude Labs"
  
    let e = document.getElementById('canonicallink')
    if(e != null) {
      document.head.removeChild(e)
    }

    let link:HTMLLinkElement = document.createElement('link')

    link.id = "canonicallink"
    link.rel = 'canonical';
    link.href = 'https://highaltitudelabs.com/puppies/enrichment/';
    link.setAttribute("data-react-helmet","true")
    document.head.appendChild(link);


},[])
    return (

      
      <div>

        <section className="littercontainer" >

          <div className="row">
            <div className="col s12">
            <h4>Puppy Enrichment Program</h4><hr></hr>
              <h5>Handling- Mild stress handling during the neonate period  </h5>
                <p><img className="inline-left" src="/img/puppy-temperment-hold0.jpg"/>
                Puppies exposed to mildly stressful experiences from a very early age (1-6) weeks usually develop into dogs possessing superior problem solving ability, with less emotional imbalance than their counterparts raised without such stimulation. In the young pup, in addition to raising the heart rate, stress causes an involuntary hormonal reaction in the adrenal-pituitary system, a help in resisting disease and handling stress. 
                The overall effect of this is to prime the entire system, building it up and making it more resilient to emotionally challenging experiences later in life. We find that at each particular stage of growth, the specific types of handling enhance the development of pups and orient them in a positive manner to later life. When puppies receive consistent, non-traumatic handling, they become more outgoing and friendly and show less inclination to be fearful once they are older.
                <br></br><br></br>At High Altitude Labs we schedule regular periods of handling making sure that each puppy receives daily handling from different members of the family.  
                </p>
                <p>Mild stress handling during the transitional period which begins when the puppies have fully opened their eyes consists of: 
                <br></br><br></br>
<h6>Elevation Exercise</h6><br></br>
<img className="inline-right" src="/img/puppy-temperment-hold.jpg"/>
We hold the pup up in the midair until the pup begins to squirm and protest. We then draw the pup close and stroke the pup gently to allow the pup to settle down. 
<br></br><br></br>
<h6>Dominance Exercise </h6><br></br>

We place the pup on a soft surface, roll the pup on it’s back, and hold the pup there for 15 seconds. After the pup begins to struggle and squeal, which most do, we turn him upright again and stroke him gently. After a week of this the pups will associate the gentle petting with the end of stress. It also helps dispose the pups to human presence and handling, which will be increased in the upcoming weeks.  
<br></br><br></br>
<h6>Grooming Exercise </h6><br></br>
<img className="inline-right" src="/img/puppy-temperment-hold2.jpg"/>
Weekly grooming sessions will be performed on each pup that teaches the puppies how to be handled and touched. The pup’s will get their ears cleaned, nails clipped, and a light brushing on their fur.   </p>
              </div>
          </div>
        </section>
      </div>
    );

}
export default EnrichmentProgram