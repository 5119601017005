import react from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";

import DogBowie from "../../dogs/dog_bowie";  
import DogCharlie from "../../dogs/dog_charlie";

function LitterBowieCharlie01() {
  return (


    <div >
      <Helmet>
       <title>Litters - Bowie and Charlie 2022 - High Altitude Labrador Retrievers</title>
       <link rel="canonical" href="https://highaltitudelabs.com/litters/previous/bowie_charlie_2023" />
     </Helmet>

     <section className="littercontainer">
        <div><h6 className="main-blue">Bowie and Charlie 2023</h6><hr></hr></div>
          <div className="row">
            <div className="col s12">
            <br></br>
            <h6>Puppies Whelped 2023-12-22! Ready to go Home 2024-02-16.</h6>
            <div className="bulletList">
              
              <ul>
                <li>
                  <NavLink to="/litters/previous/bowie_charlie_2023/puppies">Puppy Pictures</NavLink>
                </li>
              </ul>
            </div>
          <div><h6 className="main-blue">Dam</h6><hr></hr></div>
          <DogBowie />
          <div><h6 className="main-blue">Sire</h6><hr></hr></div>
          <DogCharlie />
            </div>
          </div>
        </section>
    </div>


  );
}

export default LitterBowieCharlie01
