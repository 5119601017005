import react, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const LittersCurrent = () => {
    let location = useLocation();
    let nav = useNavigate()
    useEffect(() => {
      if (location.pathname.endsWith("current")) {
        nav("nocurrentlitter")
      }
    });
    return (
        <Outlet/>

    );

}
export default LittersCurrent