import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';



const ImageSlide = (props:any) => {
    console.log("ImageSlide PROPS:",props)
    const images = [
        "/img/our-babies_00.jpg",
        "/img/our-babies_01.jpg",
        "/img/our-babies_02.jpg",
        "/img/our-babies_03.jpg",
        "/img/our-babies_04.jpg",
        "/img/our-babies_05.jpg",
        "/img/our-babies_06.jpg",
        "/img/our-babies_07.jpg",
        "/img/our-babies_08.jpg",
        "/img/our-babies_09.jpg",
        "/img/our-babies_10.jpg",
        "/img/our-babies_11.jpg",
        "/img/our-babies-12.jpg"
    ];
    function imageItem(imagePath:string, imageText: string): string { 
        
    let imageItem = `<div className="each-slide-effect">
        <div style={{ 'backgroundImage': 'url(${imagePath})' }}>
            <span>'${imageText}'</span>
        </div>
</div>`
    return imageItem

    }
    let image0 = imageItem(images[0], "template")
    return (
        
        <Slide slidesToScroll={1} slidesToShow={2}>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                    <span></span>
                </div>
            </div>            
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[5]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[6]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[7]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[8]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[9]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[10]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[11]})` }}>
                    <span></span>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[12]})` }}>
                    <span></span>
                </div>
            </div>

        </Slide>
    );
};

export default ImageSlide;