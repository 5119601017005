import react from "react";
import { Helmet } from "react-helmet";
import ImageSlide from "../ImageSlide";
function AboutBreeding() {
  return (
    <div>
        <Helmet>
          <title>Breeding Program - High Altitude Labrador Retrievers</title>
          <link rel="canonical" href="https://highaltitudelabs.com/about/breeding-program" />
        </Helmet>

      <main>  <div className="main-content">
        <div className="card">
          <h5>Breeding Program</h5>
        <img className="inline-left" src="/img/about-dad.jpg" alt="Boz"/>
          <p>For me it started with Ivory. She was a little yellow Labrador that was a “gift” from my dad to my 4 year old son. I was raised with labs but had not yet owned one of my own. My dad knew that the only dog for his grandson was a Labrador Retriever. There were strings attached to this “gift”. The deal was that I would breed this dog and my dad would get to pick a puppy from the litter. I just wanted the dog so I agreed. I had no idea what I agreed to! I had no idea that it would become a hobby, a passion, a dream.  

 

Here I am, let’s just say 20 plus years later and it is still a hobby, a passion, a dream. I am humbled by what my Labradors have done for me and my family. My father could not have possibly imagined the “gift” he gave to his grandson could do so much for so many.  
<br></br><br></br>
My breeding program is a family thing! </p>
<br></br>
<div className="card"><ImageSlide/></div>
        </div>
    
        </div>
      </main>

    </div>
  );
}

export default AboutBreeding
