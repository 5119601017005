import react from "react";
import { Helmet } from "react-helmet";

function LitterMiraRuss01Puppies() {

  return (
    <div>
      <Helmet>
       <title>Mira and Russ Puppies - High Altitude Labs</title>
       <link rel="canonical" href="https://highaltitudelabs.com/litters/previous/mira_russ_2022/puppies" />
     </Helmet>
      <div>
      <h3>Mira and Russ</h3><p className="hangingindent"><h6>
                Puppies Whelped 2022-07-10
              </h6></p>
        <section className="littercontainer">
          <div className="container">

            <div className="row">
              <div className="col s12 m6">
                <img src="/img/miralitter01/p06.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/miralitter01/p07.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/miralitter01/p08.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/miralitter01/p09.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/miralitter01/p04.jpg" />
              </div>

              <div className="col s12 m6">
                <img src="/img/miralitter01/p01.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/miralitter01/p02.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/miralitter01/p03.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/miralitter01/p05.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/puppycuddle.jpg" />
              </div>
            </div>
          </div>
        </section>
        <br></br>
      </div>
    </div>
  );
}

export default LitterMiraRuss01Puppies
