import react from "react";
import { Link, NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";

function Home() {
  return (
    <div>
        <Helmet>
          <title>Home - High Altitude Labrador Retrievers</title>
          <link rel="canonical" href="https://highaltitudelabs.com/" />
        </Helmet>
      <header id="home-page" className="page-header z-depth-3">

        <div className="section no-pad-bot" id="index-banner">
        <div className="container header-content">
        <div className="section">
          <div className="row">
            <div className="col s12 m8">
              <div className="card">
              <h1 className="main-blue">Welcome</h1>
                <p className="heavy"><span><i>Quality English Type Labrador Retrievers Since 2000</i></span>
                <br></br><br></br> We strive to produce a perfect companion with the spirit to please, 
                  the drive to succeed and the beauty to take your breath away.
                  <br></br>
                  <br></br>
                  We are very proud to provide healthy, happy and conformational sound english type AKC Labrador Retriever puppies.  Take a look around, maybe you'll find a new friend here at Erica's Labs.
                  <br></br>
                  <br></br>
                  - Erica and Steve 
                </p>
              </div>
            </div>

            <div className="col s12 m4">
              <div className="card">
                <br></br>
                <h5 className="main-blue">Available Dogs</h5>

                <p className="heavy"><NavLink to="/dogs/available">Available Dogs</NavLink><br></br><span><i>Ready for adoption</i></span></p>
                <Link to="/dogs/available"> <img src="/img/availabledogs/goose03.jpg" alt="Available Dogs" /></Link>
                
              </div>
            </div>

          </div>

        </div>
      </div>
        </div>
      </header>

      <main>  <div className="container main-content">
     
      </div>
      </main>
    </div>
  );
}

export default Home
