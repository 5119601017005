import { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

import { NavLink} from 'react-router-dom';

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const [mouseUse, setMouseUse] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    
    window.innerWidth > medium_wide && setDropdown(true);
  };

  const onMouseLeave = () => {
    setMouseUse(true);
    window.innerWidth > medium_wide && setDropdown(false);
  };

  const closeDropdown = () => {
    if (mouseUse) {
      dropdown && setDropdown(false);
      setMouseUse(false);
    }
  };
  

  const medium_wide = 600;

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown(true)}
          >
            {window.innerWidth < medium_wide && depthLevel === 0 ? (
              items.title
            ) : (
              <NavLink to={items.url}>{items.title}</NavLink>
            )}

            {depthLevel > 0 &&
            window.innerWidth < medium_wide ? null : depthLevel > 0 &&
              window.innerWidth > medium_wide ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown(true)}
          >
            {items.title}{' '}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <>
        {depthLevel > 0 ? (
          <NavLink className="child_li" to={items.url}>{items.title}</NavLink>
        ) : (
          <NavLink to={items.url}>{items.title}</NavLink>
        )}
       </>
      )}
    </li>
  );
};


export default MenuItems;
