import React from 'react';
import Navbar2 from './components/navbar2';
import Sidenav from "./components/sidenav"
import {
 Outlet
} from "react-router-dom";
import Breadcrumb from './components/breadcrumb/breadcrumb';

function App() {

  return (
    <div id="maincontainer">

      <Sidenav />
      <Navbar2 />
      <Breadcrumb />
     
      <Outlet/>
      <footer className="page-footer light-blue darken-2 z-depth-3">
          <div className="container">
            <p>
              Contact: <a href="mailto:erica@highaltitudelabs.com">Erica</a><span className='right'>&copy; High Altitude Labradors 2022</span>
            </p>
          </div>
      </footer>
    </div>
  );
}

export default App;
