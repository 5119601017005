import react from "react";
import { NavLink } from "react-router-dom";

function LitterMiraBoz01PuppiesPictures() {
  return (
    <div>
    <h3>Mira and Boz</h3><h6><p className="hangingindent">Puppies Whelped 2023-06-30 - 4 Boys and 3 Girls</p></h6>
    <section className="littercontainer">
      <div className="container"> 
        
      <div className="row">
          <div className="col s12 s12"><img src="/img/miralitter02/ptitle01.jpg" alt=""/></div>
        </div>

          <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter02/p10.jpg" /></div>
          <div className="col s12 m6"><img src="/img/miralitter02/p09.jpg" /></div>
        </div>
        <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter02/p08.jpg" /></div>
          <div className="col s12 m6"><img src="/img/miralitter02/p07.jpg" /></div>
        </div>
        <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter02/p06.jpg" /></div>
          <div className="col s12 m6"><img src="/img/miralitter02/p05.jpg" /></div>
        </div>
      <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter02/p01.jpg" /></div>
          <div className="col s12 m6"><img src="/img/miralitter02/p02.jpg" /></div>
        </div>
        <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter02/p03.jpg" /></div>
          <div className="col s12 m6"><img src="/img/miralitter02/p04.jpg" /></div>
        </div>

        <br></br>
      </div>


    </section>
    <br></br>
  </div>
  );
}

export default LitterMiraBoz01PuppiesPictures
