
import react, { useEffect } from "react";
import "./dogDetails.css"
function DogDetails(props: any) {
    let i = 0
    return (

        <div className="dog-details">
            <h6>{props.name}</h6><hr></hr>
            {props.content}
            <table>
                <tbody>
                    {props.detailItems.map((kv: any) => (
                        <tr key={kv.id}><td className="main-blue right key-column">{kv.text}</td><td>{kv.value}</td></tr>
                    ))}
                </tbody></table>
                    </div>
                    );
}

                    export default DogDetails
