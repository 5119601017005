import react from "react";
import mainImage from "../../assets/img/charlie.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";

function DogCharlie() {
  return (
    <div>
      <header></header>
      <section>
        <div className="container">

          <div>

            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                  <h5>CH Atlantic's All in Good Time</h5>
                  <div className="card-image z-depth-2">
                    <img src={mainImage} />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="container">
                    <DogDetails name='"Charlie"' content={(<div></div>)} detailItems={[{ id: 1, text: "Status", value: "Hired" }, { id: 2, text: "DOB", value: "8/18/2021" }
                      , { id: 3, text: "OFA Prelims Hips", value: "Excellent" }
                      , { id: 4, text: "OFA Prelim Elboes", value: "Normal" },
                    { id: 5, text: "Eye clearance", value: "Cleared" }, { id: 6, text: "Heart Echo", value: "clear" }
                      , { id: 7, text: "Clear for:", value: "PRA, EIC, HNPK, SM, Stargardt" }
                    ]} />

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="AM CH Atlantic's All In Good Time" lineage={["CH Clearcreek Bonaventure Dust In The Wind", "CH Atlantic's Once Upon A Time", "CH Hyspire Maritime Evertyhing You Always Wanted", "GCHB CH Clearcreek Bonaventure Windjammer JH"
                  , "CH Wiscoy's Rocco", "GCH CH Atlantic's Nothin' But Lilies", "GCH CH Shot Hyspire Impressive", "CH Maritime Forecast Matilde"
                  ,"CH Bonaventure's Clipper","Clearcreek Bonaventure Aspens Run", "GCH CH Waifin's Fly Cabot to Torngat", "Alldans Gipsy Star", "GCHS CG Gateway's Nothing But Trouble", "GCH CH Atlantic'S Little Bo Peep JH"]} />
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogCharlie
