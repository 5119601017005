import react from "react";
import mainImage from "../../assets/img/boys-russ-17-months.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";

function DogRuss() {
  return (
    <div>
      <header></header>
      <section>
        <div className="container">

          <div>

            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                  <h5>CH Gallivant Black Russian</h5>
                  <div className="card-image z-depth-2">
                    <img src={mainImage} />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="container">
                    <DogDetails name='"Russ"' content={(<div></div>)} detailItems={[{ id: 1, text: "Status", value: "Hired" }, { id: 2, text: "DOB", value: "8/18/2016" }
                      , { id: 3, text: "Hip clearance", value: "Good" }
                      , { id: 4, text: "Eye clearance", value: "OFA Normal" },
                    { id: 5, text: "Heart clearance", value: "OFA Normal" }, { id: 6, text: "Elbow clearance", value: "Normal" }
                      , { id: 7, text: "prcd-PRA status", value: "Paw Print Clear" }, { id: 8, text: "Genotype", value: "B/C" }
                      , { id: 9, text: "EIC status", value: "Clear" }, { id: 10, text: "HNPK status", value: "Carrier" }
                      , { id: 11, text: "CNM status", value: "Paw Print Clear" }
                    ]} />

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="AM CH Gallivant Black Russian" lineage={["CH Aster Liberti Labro Hamburg", "CH Gallivant BigPines Kenya", "CH RUS Night Single Great Genus Winners", "RUS CH Aster Liberti Labro Gollandiya"
                  , "BISS AM GCH Can CH Paradocs Bellwether Heath", "BISS GCH CH Big Pine Faith In Africa", "CH Tweedledum Good Vibrations WC", "CH INT Holstep's April Smile"
                  ,"CH Mallorn's Madmax","GCH Aster Liberti Labro Era", "GCH CH Paradocs Obsidian", "Bellwether Crosslots to Paradocs", "AM CH Tabatha's Adventure Score WC", "Black Nebula Anguiano"]} />
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogRuss
