import react, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

class Sidenav extends Component {
  closeSide() {
    var elems = document.querySelectorAll("#slide-out");

    M.Sidenav.getInstance(elems[0]).close();
  }

  render() {
    return (
      <div>
        <ul id="slide-out" className="sidenav">
          <li>
            <NavLink className="sidenav-close" to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className="sidenav-close" to="dogs">
              Dogs
            </NavLink>
            <ul>
              <li>
                <NavLink className="sidenav-close" to="dogs/girls">
                  Girls
                </NavLink>
                <ul>
                  <li>
                    <NavLink className="sidenav-close" to="dogs/girls/bowie">
                      Bowie
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="sidenav-close" to="dogs/girls/mira">
                      Mira
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="sidenav-close" to="dogs/boys">
                  Boys
                </NavLink>
                <ul>
                  <li>
                    <NavLink className="sidenav-close" to="dogs/boys/goose">
                      Goose
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="sidenav-close" to="dogs/boys/maso">
                      Maso
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="sidenav-close" to="dogs/available">
                  Available
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink className="sidenav-close" to="litters">
              Litters
            </NavLink>
            <ul>
              <li>
                <NavLink className="sidenav-close" to="litters/current">
                  Current
                </NavLink>
                <ul>

                </ul>
              </li>
              <li>
                <NavLink className="sidenav-close" to="litters/previous">
                  Previous
                </NavLink>
                <ul>
                <li>                    <NavLink
                      className="sidenav-close"
                      to="litters/previous/bowie_charlie_2023"
                    >
                      Bowie and Charlie
                    </NavLink></li>
                <li>
                    <NavLink
                      className="sidenav-close"
                      to="litters/previous/mira_boz_2023"
                    >
                      Mira and Boz
                    </NavLink>
                    <ul>
                      <li>
                        <NavLink
                          className="sidenav-close"
                          to="litters/previous/mira_boz_2023/puppies"
                        >
                          Puppies
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      className="sidenav-close"
                      to="litters/previous/mira_russ_2022"
                    >
                      Mira and Russ
                    </NavLink>
                    <ul>
                      <li>
                        <NavLink
                          className="sidenav-close"
                          to="litters/previous/mira_russ_2022/puppies"
                        >
                          Puppies
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <NavLink className="sidenav-close" to="puppies">
              Puppies
            </NavLink>
            <ul>

              <li>
                <NavLink className="sidenav-close" to="puppies/enrichment">
                  Enrichment Program
                </NavLink>
              </li>
              <li>
                <NavLink className="sidenav-close" to="puppies/gallery">
                  Gallery
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink className="sidenav-close" to="about">
              About
            </NavLink>
            <ul>
              <li>
                <NavLink className="sidenav-close" to="about/breeding-program">
                  Breeding Program
                </NavLink>
              </li>
              <li>
                <NavLink className="sidenav-close" to="about/how-to-buy">
                  How to Buy
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink className="sidenav-close" to="contact">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default Sidenav;
