import react, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const LittersPrevious = () => {
    let location = useLocation();
    let nav = useNavigate()
    useEffect(() => {
      if (location.pathname.endsWith("previous")) {
        nav("mira_russ_2022")
      }
    });
    return (
      <Outlet/>
    );

}
export default LittersPrevious