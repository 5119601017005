import react from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FacebookIcon, FacebookShareButton } from "react-share";

function LitterBowieCharlie01Puppies() {
  return (
    <div>
      <Helmet>
        <title>Bowie and Charlie Puppies - High Altitude Labs</title>
        <link
          rel="canonical"
          href="https://highaltitudelabs.com/litters/previous/bowie_charlie_2023/puppies"
        />
        <meta
          property="og:url"
          content="https://highaltitudelabs.com/litters/previous/bowie_charlie_2023/puppies"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Puppies Available" />
        <meta
          property="og:description"
          content="English Labrador Retriever Puppies"
        />
        <meta
          property="og:image"
          content="https://highaltitudelabs.com/img/bowielitter01/p01.jpg"
        />
      </Helmet>
      <div id="fb-root"></div>

      <div>
        <h3>Bowie and Charlie</h3>{" "}
        <p className="hangingindent">
          <h6>Puppies Whelped 2023-12-22</h6>

          <p className="hangingindent">
            Black and Yellow
          </p>
        </p>
        <section className="littercontainer">
          <div className="container">
            <div className="row">
              <div className="col s12 s12">
                <img src="/img/bowielitter01/phome.jpg" alt="" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/bb01.png" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/bb02.png" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/bg01.png" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/bg02.png" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/yg01.png" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/12/yg02.png" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p1801.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p1802.jpeg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p1803.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p1804.jpeg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p1805.jpeg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p07.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p08.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p09.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p10.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p11.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p12.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p13.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p14.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p01.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p02.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p03.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p04.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p05.jpg" />
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p06.jpg" />
              </div>
              <div className="col s12 m6">
                <img src="/img/bowielitter01/p07.jpg" />
              </div>
            </div>
          </div>
        </section>
        <br></br>
      </div>
    </div>
  );
}

export default LitterBowieCharlie01Puppies;
