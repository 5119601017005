import react from "react";
import { Helmet } from "react-helmet";
import ImageSlide from "../ImageSlide";
import { NavLink } from "react-router-dom";
function AboutPurchasing() {
  return (
    <div>
        <Helmet>
          <title>How to Buy - High Altitude Labrador Retrievers</title>
          <link rel="canonical" href="https://highaltitudelabs.com/about/how-to-buy" />
        </Helmet>

      <main>  <div className="main-content">
        <div className="card">
          <h5>How to get your Puppy!</h5>
          <div className="container">
          <p className="hangingindent">
            <NavLink to="/contact">Contact Erica</NavLink> to talk about availability and buying a puppy.<br></br>
            </p>
             <p>
              - You pick your puppy on the day of pick-up.
             </p>
          </div>
<br></br>
<div className="card"><ImageSlide/></div>
        </div>
    
        </div>
      </main>

    </div>
  );
}

export default AboutPurchasing
