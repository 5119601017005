import react, { useEffect } from "react";
import { Link } from "react-router-dom";

function DogsAvailable() {
  useEffect(() => {
    document.title = "Available Dogs - High Altitude Labs";

    let e = document.getElementById("canonicallink");
    if (e != null) {
      document.head.removeChild(e);
    }

    let link: HTMLLinkElement = document.createElement("link");

    link.id = "canonicallink";
    link.rel = "canonical";
    link.href = "https://highaltitudelabs.com/dogs/available/";
    link.setAttribute("data-react-helmet", "true");
    document.head.appendChild(link);
  }, []);

  return (
    <div>
      <section className="card">
        <div className="row">
          <div className="col s12">
            <div className="container">
              <div className="row">
                <div className="col s12 m12">
                  <h6>Available 13 Month Black Boy</h6>
                  <br/><Link to="/dogs/boys/goose">See Dog Page for more information</Link>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <img src="/img/availabledogs/goose03.jpg" />
                </div>
                <div className="col s12 m6">
                  <img src="/img/availabledogs/goose01.jpg" />
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <img src="/img/availabledogs/goose02.png" />
                </div>
                <div className="col s12 m6">
                </div>
              </div>
              <div className="row">
                <div className="col s12 m12">
                  <h6>Available 9 Month Yellow Boy</h6>
                  <br/><Link to="/litters/previous/bowie_charlie_2023">See Litter Page for more information</Link>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <img src="/img/availabledogs/chip01.jpg" />
                </div>
                <div className="col s12 m6">
                  <img src="/img/availabledogs/chip02.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DogsAvailable;
