import react from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

function Contact() {
  return (
    <div>
        <Helmet>
          <title>Contact - High Altitude Labrador Retrievers</title>
          <link rel="canonical" href="https://highaltitudelabs.com/contact/" />
        </Helmet>
      <header id="contact-page" className="page-header z-depth-3">

        <div className="section no-pad-bot" id="index-banner">
        <div className="container header-content">
        <div className="section">
          <div className="row">
            <div className="col s12 m6">
              <div className="card">
              <h4 className="main-blue">Contact</h4>
                <p className="heavy"><span>email: </span><a href="mailto:erica@highaltitudelabs.com">erica@highaltitudelabs.com</a>
                  <br></br>
                  <span>map: </span><a href="https://www.bing.com/maps?q=hendersonville+tn" target="_blank">Hendersinville, TN</a>
                  <br></br>
                  <span>phone: </span>(928) 607-8452
                </p>
              </div>
            </div>

            <div className="col s12 m4">

            </div>

          </div>

        </div>
      </div>
        </div>
      </header>

      <main>  <div className="container main-content">
     
      </div>
      </main>
    </div>
  );
}

export default Contact
