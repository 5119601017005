import react from "react";
import { Helmet } from "react-helmet";
import LitterMiraBoz01PuppiesPictures from "./mira_boz_01_puppies_pictures";


function LitterMiraBoz01Puppies() {

  return (
    <div>
      <Helmet>
       <title>Mira and Boz Puppies - High Altitude Labs</title>
       <link rel="canonical" href="https://highaltitudelabs.com/litters/previous/mira_boz_2023/puppies" />
     </Helmet>
     <LitterMiraBoz01PuppiesPictures/>
    </div>
   
    
  );
}

export default LitterMiraBoz01Puppies
