import react, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./puppies.css"
const Puppies = () => {

  let location = useLocation();
  let nav = useNavigate()
  useEffect(() => {
    if (location.pathname === "/puppies") {
      nav("available")
    }
  });
    return (
      <div id="puppies">
        <section className="card">

          <div className="row">
            <div className="col s12">
              <Outlet/>
            </div>
          </div>
        </section>
      </div>
    );

}
export default Puppies