import react, { Component } from "react";

import { menuItems } from "../data/menu/main";
import M from 'materialize-css';
import MenuItems from './MenuItems';
import "./navbar1.css"


class Navbar2 extends Component {

    componentDidMount() {
        var elems = document.querySelectorAll('#slide-out');
        console.log("navbar init")
        var instances = M.Sidenav.init(elems)
      }

  openSide() {

    var elems = document.querySelectorAll('#slide-out');

    M.Sidenav.getInstance(elems[0]).open()

  }

 
  render() {

    return (
      <div>

<nav className="light-blue darken-2 z-depth-3" role="navigation">
      <a href="#!" onClick={this.openSide} data-target="slide-out" className="sidenav-trigger hide-on-med-and-up"><i className="material-icons">menu</i></a>
      <div className="nav-wrapper container ">
              <ul className="hide-on-small-and-down">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
      </div>
    </nav>
      </div>
    )
  }
}

export default Navbar2