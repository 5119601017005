import react from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
function NoCurrentLitter() {
  return (
    <div>
      <Helmet>
        <title>How to Buy - High Altitude Labrador Retrievers</title>
        <link
          rel="canonical"
          href="https://highaltitudelabs.com/litters/current/none"
        />
      </Helmet>

      <main>
        {" "}
        <div className="main-content">
          <div className="card">
            <h5>Sorry we do not have a current litter!</h5>
            <div className="container">
              <p className="hangingindent">
                <NavLink to="/litters/previous">See Previous Litters.</NavLink>
                <br></br>
                </p>
                <NavLink to="/dogs/available">
                  See Available Older Dogs.
                </NavLink>
                <br></br>
             
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NoCurrentLitter;
