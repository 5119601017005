import react from "react";
import mainImage from "../../assets/img/boz.jpg"
import DogDetails from "../dogDetails/dogDetails";
import Pedigree from "../pedigree/pedigree";

function DogBoz() {

  return (
    <div>
      <header></header>
      <section>
        <div className="container">

          <div>

            <div className="section">
              <div className="row card">
                <div className="col s12 m6">
                  <h5>Aragon’s Under The Big Top At Ashland</h5>
                  <div className="card-image z-depth-2">
                    <img src={mainImage} />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="container">
                    <DogDetails name='"BOZ"' content={(<div></div>)} detailItems={[{ id: 1, text: "Status", value: "Hired" }, { id: 2, text: "DOB", value: "2019-09-15" }
                      , { id: 3, text: "Hip clearance", value: "Normal" }
                      , { id: 4, text: "Eye clearance", value: "OFA Normal" },
                    { id: 5, text: "Heart clearance", value: "OFA Normal" }, { id: 6, text: "Elbow clearance", value: "Normal" }
                      , { id: 7, text: "DNA", value: "Clear PRA, EIC. CNM, DM, HNPK, MCD, RD/OSD, SK2, Stargardt Disease and Dilute CLEAR" }, { id: 8, text: "Genotype", value: "Dominant Chocolate" }
                    ]} />

                  </div>
                </div>

              </div>
              <div className="row card">
                <div className="col s12 m12">
                  <h5>Pedigree</h5>
                  <Pedigree self="Aragon’s Under The Big Top At Ashland" lineage={["GCH.Epoch's Moccasin Joe","Ashland's Let The Sunshine In","GCH.Quail Chase Broadway Joe Windfall","Epoch's Treasure","MBISS GRCh Glacieridge Tabatha's Granite","Ashland's Don't Rain On MY Parade!",
                    "Liberty Creeks Broad Ridge, TDI","Dune Dancer's Windfall At QuailChase","CH. Willcare Leisure Suit Larry, JH","CH.Epoch's Brown Baggin'","CH Tabatha's Sunfest Donnybrook","GRCH. Tabatha's Galore","AM/MEX CH. Ashland's Taylor Made","Stormy C's Sweet Serenade At Ashland"]} />
                </div>
              </div>

            </div>
          </div></div>

      </section>
    </div>
  );
}

export default DogBoz
