export const bowie_lineage = [
    "Ch. Wiscoys Rocco",
    "Ch. Riverlane Our Girl Friday",
    "Ch. Waifin's Fly Cabot to Torngat",
    "Ch. Alldans Gipsy Star",
    "GCHS Ch. Shalimar's The Animator",
    "Riverlane Skye's Black Friday",
    "Ch. Mallorn's Aldebaron",
    "Ch. Waifin's High Flying Adored",
    "Ch. Union Jack of Misty Dreams",
    "Call Me Ones Of Misty Dreams",
    "Ch. Nipntuck Hyspire Unforgettable JH",
    "Shalimar Hyspire Biding My Time",
    "Ch. Ghoststone's Otterly Fantastic Hollywood Kid JH",
    "Ch. Skye's Are You The One JH CD"
];