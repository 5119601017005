import react from "react";
import { Helmet } from "react-helmet";

import DogMira from "../../dogs/dog_mira";
import DogBoz from "../../dogs/dog_boz";
import { Link, NavLink } from "react-router-dom";

function LitterMiraBoz01() {
  return (
    <div>
      <Helmet>
        <title>
          Litters - Mira and Boz 2023 - High Altitude Labrador Retrievers
        </title>
        <link
          rel="canonical"
          href="https://highaltitudelabs.com/litters/previous/mira_boz_2023"
        />
      </Helmet>

      <section className="littercontainer">
        <div>
          <h6 className="main-blue">Mira and Boz 2023</h6>
          <hr></hr>
        </div>

        <div className="row">
          <div className="col s12">
            <br></br>
            <h6>Puppies Whelped 2023-06-30! <span><i> Ready to go Home on 2023-08-25!</i></span></h6>
            <div className="bulletList">
              
              <ul>
                <li>
                  <NavLink to="/litters/previous/mira_boz_2023/puppies">Puppy Pictures</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>

            <div>
              <h6 className="main-blue">Dam</h6>
              <hr></hr>
            </div>
            <DogMira />
            <div>
              <h6 className="main-blue">Sire</h6>
              <hr></hr>
            </div>
            <DogBoz />
          </div>
        </div>
      </section>
    </div>
  );
}

export default LitterMiraBoz01;
