import { litters } from "./litters"
import { puppies } from "./puppies"
import { dogs } from "./dogs"
import { purchasing } from "./about";


export const menuItems = [
        {title:"Home", url:"home"},
        {title:"Dogs", url:"", submenu:dogs},
        {title:"Litters", url:"", submenu: litters},
        {title:"Puppies", url:"", submenu:puppies},
        {title:"About", url:"", submenu:purchasing},
        {title:"Contact", url:"contact"},
    ];