import react from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";

import DogMira from "../../dogs/dog_mira";
import DogRuss from "../../dogs/dog_russ";

function LitterMiraRuss01() {
  return (


    <div >
      <Helmet>
       <title>Litters - Mira and Russ 2022 - High Altitude Labrador Retrievers</title>
       <link rel="canonical" href="https://highaltitudelabs.com/litters/previous/mira_russ_2022" />
     </Helmet>

     <section className="littercontainer">
        <div><h6 className="main-blue">Mira and Russ 2022</h6><hr></hr></div>
          <div className="row">
            <div className="col s12">
            <br></br>
            <h6>Puppies Whelped 2022-07-10!</h6>
            <div className="bulletList">
              
              <ul>
                <li>
                  <NavLink to="/litters/previous/mira_russ_2022/puppies">Puppy Pictures</NavLink>
                </li>
              </ul>
            </div>
          <div><h6 className="main-blue">Dam</h6><hr></hr></div>
          <DogMira />
          <div><h6 className="main-blue">Sire</h6><hr></hr></div>
          <DogRuss />
            </div>
          </div>
        </section>
    </div>


  );
}

export default LitterMiraRuss01
