import react, { Component } from "react";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

class NotFound extends Component {
  componentDidMount() {
    
  }
  render() {
    
    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <section className="tabs-container">
          <div className="row">
            <div className="col s12">
                <h1>404 Page not found</h1>
            </div>
            
          </div>
        </section>
      </div>
    );
  }
}
export default NotFound