import react, { useEffect } from "react";
import "./about.css"
import { Outlet, useLocation, useNavigate } from "react-router-dom";
function About() {
  let location = useLocation();
    let nav = useNavigate()
    useEffect(() => {

      if (location.pathname === "/about") {
        nav("breeding-program")
      }

    });
    return (
      <div>

        <section >
          <div className="row">
            <div className="col s12">
              <Outlet/>
            </div>
          </div>
        </section>
      </div>
    );
  }

export default About
