import react, { useEffect } from "react";
import { Link,  useLocation} from "react-router-dom";
import "./breadcrumb.css"
function Breadcrumb() {
    let loc = useLocation().pathname.split("/").filter((v:any) => v != "")
    let thispath = ""
    let thisID = ""
    
    let i = 1;

  return (
<nav  id="breadcrumb" className=" z-depth-0">
<div className="nav-wrapper">
  <div className="col s12">
    <div >{ loc.map((v:any) => {
        thispath = thispath + "/" + v
        thisID = thisID + "_" + v
        let addClass = "breadcrumb "
        let display = formatDisplay(v)
        return (<Link key={"crumb"+thisID} to={thispath} className={addClass}>{display}
        </Link>)
    })}</div>
  </div>
</div>
</nav>
  );


  function formatDisplay(v:string) {
    let dsp = v.split("_")
    let sep = ""
    let display = ""
    dsp.forEach((d:string) => {
      display += sep + d.charAt(0).toUpperCase() + d.slice(1)
      sep = " "
    })

    return display
  }

}

export default Breadcrumb