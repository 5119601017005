import react, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./litters.css"
const Litters = () => {

  let location = useLocation();
  let nav = useNavigate()
  useEffect(() => {
    if (location.pathname == "/litters") {
      nav("current")
    }

  });
    return (

      
      <div>

        <section className="card">

          <div className="row">
            <div className="col s12 ">
              <Outlet/>
            </div>
          </div>
        </section>
      </div>
    );

}
export default Litters