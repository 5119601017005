import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Home from "./components/home";
import Dogs from "./components/dogs";
import DogMira from "./components/dogs/dog_mira";
import DogRuss from "./components/dogs/dog_russ";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import About from "./components/about/about";
import Contact from "./components/contact";
import NotFound from "./components/notFound";
import DogsGirls from "./components/dogs_girls";
import DogBowie from "./components/dogs/dog_bowie";
import ComingSoon from "./components/ComingSoon";
import PuppiesGallery from "./components/puppies/puppies_gallery";
import Litters from "./components/litters/litters";
import LittersCurrent from "./components/litters/current";
import LitterMiraBoz01 from "./components/litters/mira_boz_01/mira_boz_01";
import LittersPrevious from "./components/litters/previous";
import LitterMiraRuss01 from "./components/litters/mira_russ_01/mira_russ_01";
import Puppies from "./components/puppies/puppies";
import PuppiesAvailable from "./components/puppies/puppies_available";
import EnrichmentProgram from "./components/puppies/enrichment_program";
import HiredDogBoz from "./components/dogs/hired_dog_boz";
import DogsBoys from "./components/dogs_boys";
import AboutPurchasing from "./components/about/about_purchasing";
import AboutBreeding from "./components/about/about_breeding";
import LitterMiraRuss01Puppies from "./components/litters/mira_russ_01/mira_russ_01_puppies";
import LitterMiraBoz01Puppies from "./components/litters/mira_boz_01/mira_boz_01_puppies";
import DogMaso from "./components/dogs/dog_maso";
import DogGoose from "./components/dogs/dog_goose";
import NoCurrentLitter from "./components/litters/nocurrent";
import DogsAvailable from "./components/dogs/dogs_available";
import LitterBowieCharlie01 from "./components/litters/bowie_charlie_01/bowie_charlie_01";
import LitterBowieCharlie01Puppies from "./components/litters/bowie_charlie_01/bowie_charlie_01_puppies";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="dogs" element={<Dogs />}>
            <Route path="girls" element={<DogsGirls />}>
              <Route index element={<DogBowie />}/>
              <Route path="bowie" element={<DogBowie />} />
              <Route path="mira" element={<DogMira />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="boys" element={<DogsBoys />}>
              <Route path="Goose" element={<DogGoose />} />
              <Route path="Maso" element={<DogMaso />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="available" element={<DogsAvailable />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="litters" element={<Litters />}>
            <Route path="current" element={<LittersCurrent />}>
              <Route path="nocurrentlitter" element={<NoCurrentLitter/>} />
              <Route path="*" element={<NoCurrentLitter />}></Route>
            </Route>
            <Route path="previous" element={<LittersPrevious />}>
              <Route path="mira_boz_2023" element={<LitterMiraBoz01 />} />
              <Route path="mira_boz_2023/puppies" element={<LitterMiraBoz01Puppies />} />
              <Route path="mira_russ_2022" element={<LitterMiraRuss01 />} />
              <Route path="mira_russ_2022/puppies" element={<LitterMiraRuss01Puppies />} />
              <Route path="bowie_charlie_2023" element={<LitterBowieCharlie01 />} />
              <Route path="bowie_charlie_2023/puppies" element={<LitterBowieCharlie01Puppies />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="puppies/*" element={<Puppies />}>
            <Route path="available" element={<PuppiesAvailable />} />
            <Route path="enrichment" element={<EnrichmentProgram />} />
            <Route path="care" element={<EnrichmentProgram />} />
            <Route path="gallery" element={<PuppiesGallery />} />
            <Route path="*" element={<NotFound />}></Route>
          </Route>

          <Route path="about/*" element={<About />}>
            <Route path="breeding-program" element={<AboutBreeding />}></Route>
            <Route path="how-to-buy" element={<AboutPurchasing />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Route>
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <Outlet></Outlet>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
