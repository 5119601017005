import react, { useEffect } from "react";
import ImageSlide from "../ImageSlide";

function PuppiesGallery() {
  useEffect(() => {

    document.title = "Puppy Gallery - High Altitude Labs"
  
    let e = document.getElementById('canonicallink')
    if(e != null) {
      document.head.removeChild(e)
    }

    let link:HTMLLinkElement = document.createElement('link')

    link.id = "canonicallink"
    link.rel = 'canonical';
    link.href = 'https://highaltitudelabs.com/puppies/gallery/';
    link.setAttribute("data-react-helmet","true")
    document.head.appendChild(link);


},[])
    return (
      <div>
      <header id="puppies-gallery-page" className="page-header z-depth-3">

<div className="section no-pad-bot" id="index-banner">
  <div className="container" >
    <h1 className="orange-text">Puppy Love</h1>


    <div className="hide-on-small-only">
      <br></br>
      <br ></br>
      <br></br>
    </div>
    
  </div>
</div>
</header>
<div >
  
<div>
      <section className="littercontainer">
        <div className="container"> 

        <div className="row">
            <div className="col s12 m6"><img src="/img/miralitter01/p06.jpg" /></div>
            <div className="col s12 m6"><img src="/img/miralitter01/p07.jpg" /></div>
          </div>
          <div className="row">
            <div className="col s12 m6"><img src="/img/miralitter01/p08.jpg" /></div>
            <div className="col s12 m6"><img src="/img/miralitter01/p09.jpg" /></div>
          </div>
          <div className="row">
          <div className="col s12 m6"><img src="/img/miralitter01/p04.jpg" /></div>
            
            <div className="col s12 m6"><img src="/img/miralitter01/p01.jpg" /></div>
          </div>
          <div className="row">
            <div className="col s12 m6"><img src="/img/miralitter01/p02.jpg" /></div>
            <div className="col s12 m6"><img src="/img/miralitter01/p03.jpg" /></div>
          </div>
          <div className="row">
            <div className="col s12 m6"><img src="/img/miralitter01/p05.jpg" /></div>
            <div className="col s12 m6"><img src="/img/puppycuddle.jpg" /></div>
          </div>

        </div>


      </section>
      <br></br>
    </div>
  <div className="card"><ImageSlide/></div>
            

          </div></div>
        
);
}

export default PuppiesGallery
