export const mira_lineage = [
    "CH Zinfndel Lengley\'s Big Papi",
    "Riverlane Skye's Black Friday", 
    "CH Dickendall Buckstone Three Dog Night", 
    "Lengley's Consider The Lillies Of Zinfndel", 
    "CH Ghoststone's Otterly Fantastic Hollywood Kid JH", 
    "CH Skye's Are You The One JH CD", 
    "CH Saddlehill Late Night Scramble", 
    "CH Dickendall Princess Phoebe", 
    "CH Wit's End Jack or Better at Blackwing", 
    "Zinfndel's Contessa of Lengley", 
    "CH Ghoststone Hyspire Dressed to Impress", 
    "CH Ghoststone's Hollywood Blond", 
    "CH Skyes Big Black Guy JH CD", 
    "CH Westdale Wind in the Willows"]